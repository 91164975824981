import React from "react";
import {
    BrowserRouter as Router,
    // Navigate,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";


import Navbar from "./Navbar/Navbar.js";
import Home from "./Home/Home.js";
import AddCredits from "./AddCredits/AddCredits.js"

import AuthLogin from "./Auth/AuthLogin.js"
import AuthRegister from "./Auth/AuthRegister.js"
import VerifyEmail from "./Auth/VerifyEmail.js"

import ProtectedRoute from "./ProtectedRoute/ProtectedRoute.js";


function Components() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/home" element={<ProtectedRoute path="/" element={Home} />} />
                <Route path="/add-credits" element={<ProtectedRoute path="/" element={AddCredits} />} />

                <Route path="/login" element={<AuthLogin />} />
                <Route path="/register" element={<AuthRegister />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                
                <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
        </Router>
    );
}

export default Components;
