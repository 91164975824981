import React, { useState } from "react";
import { verifyEmail, resendVerificationCode } from "./AuthService";
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleVerify = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    const response = await verifyEmail(verificationCode);

    setLoading(false);
    setMessage(response.message);

    if (response.success) {
      setTimeout(() => navigate("/login"), 2000);
    }
  };

  const handleResendCode = async () => {
    setMessage("");
    const response = await resendVerificationCode();
    setMessage(response.message);
  };

  return (
    <div className="bg-black min-h-screen flex items-center justify-center px-6">
      <div className="w-full max-w-xl space-y-8">
        <h1 className="text-5xl font-extrabold text-white text-center">Verify Email</h1>

        <p className="text-lg text-gray-400 text-center">
          Enter the verification code sent to{" "}
          <span className="text-white font-semibold">{localStorage.getItem("email")}</span>.  
          <br />Verification codes expire after 3 minutes.
        </p>

        <form onSubmit={handleVerify} className="space-y-6">
          {/* Verification Code Field */}
          <div>
            <label htmlFor="verificationCode" className="block text-base font-medium text-gray-300 mb-2">
              Verification Code
            </label>
            <input
              type="text"
              id="verificationCode"
              name="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              className="w-full px-5 py-3 text-lg rounded-lg bg-gray-900 bg-opacity-20 text-white border border-gray-600 focus:ring-2 focus:ring-[#0080FF] outline-none"
              placeholder="Enter the code"
              required
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-[#007FFF] bg-opacity-20 text-[#0080FF] font-bold py-3 text-lg rounded-lg hover:bg-opacity-30 transition"
            disabled={loading}
          >
            {loading ? "Verifying..." : "Verify Email"}
          </button>
        </form>

        {/* Display Message (Success or Error) */}
        {message && (
          <p className={`mt-4 text-center text-lg ${message.includes("success") ? "text-green-400" : "text-red-400"}`}>
            {message}
          </p>
        )}

        {/* Link to Resend Code */}
        <p className="text-center text-lg text-gray-400">
          Didn't receive a code?{" "}
          <button
            className="text-[#0080FF] font-bold hover:underline"
            onClick={handleResendCode}
          >
            Resend Code
          </button>
        </p>
      </div>
    </div>
  );
};

export default VerifyEmail;
