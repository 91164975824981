const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Backend base URL

export const getCredits = async (email) => {
  try {
    const response = await fetch(`${API_BASE_URL}/check_credits`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    });

    if (response.ok) {
      const data = await response.json();
      return data.credits.credits_balance;
    } else {
      return -1;
    }
  } catch (error) {
    console.error("Error fetching credits:", error);
    return { success: false, message: "An error occurred while fetching credits." };
  }
};
