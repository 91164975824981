import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { loginUser } from "./AuthService";
import { EyeOff } from "lucide-react";

const AuthLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [pupilDir, setPupilDir] = useState({ x: 0, y: 0 });
  const [isEyeHovered, setIsEyeHovered] = useState(false);

  const eyeRef = useRef(null);

  useEffect(() => {
    if (isEyeHovered) {
      setPupilDir({ x: 0, y: 0 });
    }
  }, [isEyeHovered]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    const response = await loginUser({ email, password });

    if (response.success) {
      window.location.href = "/home";
    } else {
      setError(response.message || "Login failed. Please try again.");
    }
  };

  const handleMouseMove = (e) => {
    if (!eyeRef.current || isEyeHovered) return;

    const rect = eyeRef.current.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const dx = e.clientX - centerX;
    const dy = e.clientY - centerY;

    const angle = Math.atan2(dy, dx);
    const dist = 4;

    setPupilDir({
      x: Math.cos(angle) * dist,
      y: Math.sin(angle) * dist,
    });
  };

  const handleEyeHover = (hovering) => {
    setIsEyeHovered(hovering);
  };

  return (
    <div
      className="bg-black min-h-screen flex items-center justify-center px-6"
      onMouseMove={handleMouseMove}
    >
      <div className="w-full max-w-xl space-y-8">
        <h1 className="text-5xl font-extrabold text-white text-center">Sign In</h1>

        {error && (
          <p className="text-red-400 bg-gray-800 p-4 rounded-lg text-center text-base">
            {error}
          </p>
        )}

        <form onSubmit={handleLogin} className="space-y-6">
          {/* Email Field */}
          <div>
            <label htmlFor="email" className="block text-base font-medium text-gray-300 mb-2">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-5 py-3 text-lg rounded-lg bg-gray-900 bg-opacity-20 text-white border border-gray-600 focus:ring-2 focus:ring-[#0080FF] outline-none"
              placeholder="Enter your email"
              required
            />
          </div>

          {/* Password Field */}
          <div>
            <label htmlFor="password" className="block text-base font-medium text-gray-300 mb-2">
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-5 py-3 pr-14 text-lg rounded-lg bg-gray-900 bg-opacity-20 text-white border border-gray-600 focus:ring-2 focus:ring-[#0080FF] outline-none"
                placeholder="Enter your password"
                required
              />

              {/* Eye Icon */}
              <div
                ref={eyeRef}
                className="absolute right-4 top-1/2 -translate-y-1/2 w-6 h-6 cursor-pointer flex items-center justify-center"
                onClick={() => setShowPassword(!showPassword)}
                onMouseEnter={() => handleEyeHover(true)}
                onMouseLeave={() => handleEyeHover(false)}
              >
                <div className="relative w-6 h-6">
                  {!showPassword ? (
                    <EyeOff className="text-white w-6 h-6" />
                  ) : (
                    <>
                      {/* Eye Outline */}
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-full h-full"
                      >
                        <path d="M1 12s4-7 11-7 11 7 11 7-4 7-11 7S1 12 1 12z" />
                      </svg>

                      {/* Iris + Pupil */}
                      <div
                        className="absolute w-2 h-2 bg-white rounded-full flex items-center justify-center"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: `translate(-50%, -50%) translate(${pupilDir.x}px, ${pupilDir.y}px)`,
                          transition: "transform 0.15s ease",
                        }}
                      >
                        <div className="w-1 h-1 bg-black rounded-full" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-[#007FFF] bg-opacity-20 text-[#0080FF] font-bold py-3 text-lg rounded-lg hover:bg-opacity-30 transition"
          >
            Login
          </button>
        </form>

        {/* Register Link */}
        <p className="text-center text-lg text-gray-400">
          Don't have an account?{" "}
          <Link to="/register" className="text-[#0080FF] font-bold hover:underline">
            Register here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default AuthLogin;
