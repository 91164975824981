const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Backend base URL

/**
 * Fetch API keys for a user.
 * @param {string} email - The email of the user.
 * @returns {Object} Response data containing API keys or error.
 */
export const getAPIKeys = async (email) => {
  try {
    const response = await fetch(`${API_BASE_URL}/get_api_keys`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    });

    if (response.ok) {
      const data = await response.json();
      return { success: true, data: data.api_keys };
    } else {
      const errorData = await response.json();
      return { success: false, message: errorData.error || "Failed to fetch API keys." };
    }
  } catch (error) {
    console.error("Error fetching API keys:", error);
    return { success: false, message: "An error occurred while fetching API keys." };
  }
};


/**
 * Generate a new API key for a user.
 * @param {string} email - The ID of the user.
 * @returns {Object} Response data containing the new API key or error.
 */
export const generateAPIKey = async (email) => {
  try {
    const response = await fetch(`${API_BASE_URL}/generate_api_key`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, data: data.api_key };
    } else if (response.status === 403 && data.redirect) {
      // Alert the user before redirecting
      alert("You need to verify your email before generating an API key.");
      
      // Redirect to the verification page
      window.location.href = data.redirect;

      return { success: false, message: "Redirecting to email verification..." };
    } else {
      return { success: false, message: data.error || "Failed to generate API key." };
    }
  } catch (error) {
    console.error("Error generating API key:", error);
    return { success: false, message: "An error occurred while generating an API key." };
  }
};



/**
 * Deactivate an API key.
 * @param {string} apiKey - The API key to deactivate.
 * @returns {Object} Response data indicating success or error.
 */
export const deactivateAPIKey = async (apiKey) => {
    try {
      const response = await fetch(`${API_BASE_URL}/deactivate_api_key`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ api_key: apiKey }),
      });
  
      if (response.ok) {
        const data = await response.json();
        return { success: true, message: data.message };
      } else {
        const errorData = await response.json();
        return { success: false, message: errorData.error || "Failed to deactivate API key." };
      }
    } catch (error) {
      console.error("Error deactivating API key:", error);
      return { success: false, message: "An error occurred while deactivating the API key." };
    }
  };

