import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

// Replace with your actual publishable key from Stripe
const stripePromise = loadStripe("pk_live_51ObYQDDeoQKG3IgGyOzIXS12G2qijgs3yIzztyV9TW5v1eUVvw0TwpJbH6BRcDx4kLSPDj02slrCZmqgkmoLaiyp00k64eBepQ");

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: "1rem",
      color: "#ffffff",
      "::placeholder": {
        color: "#b0b0b0",
      },
      fontFamily: "Arial, sans-serif",
      padding: "10px 14px",
    },
    invalid: {
      color: "#ff6b6b",
      iconColor: "#ff6b6b",
    },
  },
};

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [amount, setAmount] = useState("");
  const [processing, setProcessing] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const storedUserEmail = localStorage.getItem("email");
    if (storedUserEmail) {
      setEmail(storedUserEmail);
    }
  }, []);

  // This function notifies your backend about successful payment
  const recordPayment = async (paymentIntent) => {
    const user = {
      email:
        paymentIntent.charges?.data?.[0]?.billing_details?.email || email,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}record-payment`, // Ensure trailing slash or add `/`
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            user,
            amount: paymentIntent.amount,
            paymentIntentId: paymentIntent.id,
          }),
        }
      );
      
      const data = await response.json();
      console.log("Payment recorded:", data);
    } catch (error) {
      console.error("Error recording payment:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);
    setMessage("");

    // Convert the entered dollar amount to cents.
    const paymentAmount = Math.round(parseFloat(amount) * 100);
    if (isNaN(paymentAmount) || paymentAmount < 100) {
      setMessage("Please enter a valid amount (minimum $1).");
      setProcessing(false);
      return;
    }

    try {
      // Create a PaymentIntent by calling the backend
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}create-payment-intent`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount: paymentAmount }),
        }
      );
      const { clientSecret } = await response.json();

      // Confirm the card payment using the client secret.
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (result.error) {
        setMessage(result.error.message);
      } else if (result.paymentIntent.status === "succeeded") {
        setMessage("Payment succeeded! Credits have been added. Taking you back to your homepage momentarily...");
        await recordPayment(result.paymentIntent);

        // Wait 3 seconds, then force full reload to /home
        setTimeout(() => {
          window.location.href = "/home";
        }, 1500);
      }
    } catch (error) {
      setMessage("Payment failed: " + error.message);
    }
    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-xl w-full mx-auto space-y-8">
      <h2 className="text-5xl font-extrabold text-white text-center">Add Credits</h2>
      <p className="text-lg text-gray-400 text-center">$1 = 10,000 credits</p>

      {/* Amount Field */}
      <div>
        <label htmlFor="amount" className="block text-lg font-medium text-gray-300 mb-2">
          Amount ($):
        </label>
        <input
          id="amount"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          min="1"
          step="1"
          placeholder="Enter amount in USD"
          className="w-full px-5 py-3 text-lg rounded-lg bg-gray-900 bg-opacity-20 text-white border border-gray-600 focus:ring-2 focus:ring-[#0080FF] outline-none"
        />
      </div>

      {/* Card Element */}
      <div className="p-5 bg-gray-900 bg-opacity-20 rounded-lg">
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        disabled={!stripe || processing}
        className={`w-full py-3 text-lg font-bold text-white rounded-lg transition 
          ${
            processing
              ? "bg-gray-600 cursor-not-allowed"
              : "bg-[#007FFF] bg-opacity-20 hover:bg-opacity-30"
          }`}
      >
        {processing ? "Processing..." : "Add Credits"}
      </button>

      {/* Message (Success or Error) */}
      {message && (
        <div
          className={`mt-4 text-center text-lg ${
            message.includes("succeeded") ? "text-green-400" : "text-red-400"
          }`}
        >
          {message}
        </div>
      )}
    </form>
  );
}

export default function AddCredits() {
  return (
    <div className="bg-black min-h-screen flex items-center justify-center px-6">
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
}
