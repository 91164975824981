const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Backend base URL

/**
 * Register a new user.
 * @param {Object} userData - The user data for registration.
 * @returns {Object} Response data containing user information or error.
 */
export const registerUser = async (userData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    if (response.ok) {
      const data = await response.json();
      // Store sessionToken and ourUserId in localStorage
      localStorage.setItem("ourUserId", data.user.ourUserId); // Save ourUserId
      localStorage.setItem("email", data.user.email); // Save email
      return { success: true, data: data.user };
    } else {
      const errorData = await response.json();
      return { success: false, message: errorData.error.message };
    }
  } catch (error) {
    return { success: false, message: "An error occurred while registering." };
  }
};


/**
 * Log in a user.
 * @param {Object} credentials - The login credentials (email and password).
 * @returns {Object} Response data containing user information or error.
 */
export const loginUser = async (credentials) => {
  try {
    const response = await fetch(`${API_BASE_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    });

    if (response.ok) {
      const data = await response.json();
      // Store sessionToken and ourUserId in localStorage
      localStorage.setItem("sessionToken", data.sessionToken);
      localStorage.setItem("ourUserId", data.user.ourUserId); // Save ourUserId
      localStorage.setItem("email", data.user.email); // Save email
      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, message: errorData.error.message };
    }
  } catch (error) {
    return { success: false, message: "An error occurred while logging in." };
  }
};


export const isAuthenticated = async () => {
    const sessionToken = localStorage.getItem("sessionToken");
    const email = localStorage.getItem("email");
    if (!sessionToken) {
      return { success: false, message: "No session token found." };
    }
  
    try {
      const response = await fetch(`${API_BASE_URL}/is-authenticated`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Parse-Session-Token": sessionToken,
          "email": email,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        return { success: true, data };
      } else {
        const errorData = await response.json();
        return { success: false, message: errorData.message };
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
      return { success: false, message: "An error occurred while checking authentication." };
    }
};


export const logoutUser = async () => {
    const sessionToken = localStorage.getItem("sessionToken");
  
    if (!sessionToken) {
      return { success: false, message: "No session token found." };
    }
    
    // Remove the session token from localStorage
    localStorage.removeItem("sessionToken");
    localStorage.removeItem("email");
    localStorage.removeItem("ourUserId");
    return { success: true, message: "Logged out successfully." };

  };
  

  /**
 * Verify user's email by sending the code to the backend.
 * @param {string} verificationCode - The code the user enters.
 * @returns {Object} Success or error response.
 */
export const verifyEmail = async (verificationCode) => {
  const email = localStorage.getItem("email"); // Retrieve stored email

  if (!email) {
    return { success: false, message: "No email found. Please register again." };
  }

  try {
    const response = await fetch(`${API_BASE_URL}/verify-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, code: verificationCode }),
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, message: "Email verified successfully!" };
    } else {
      return { success: false, message: data.error || "Verification failed." };
    }
  } catch (error) {
    return { success: false, message: "An error occurred during verification." };
  }
};


/**
 * Resend the verification code via email.
 * @returns {Object} Success or error response.
 */
export const resendVerificationCode = async () => {
  const email = localStorage.getItem("email"); // Retrieve stored email

  if (!email) {
    return { success: false, message: "No email found. Please register again." };
  }

  try {
    const response = await fetch(`${API_BASE_URL}/resend-verification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, message: "A new verification code has been sent to your email." };
    } else {
      return { success: false, message: data.error || "Failed to resend verification code." };
    }
  } catch (error) {
    return { success: false, message: "An error occurred while resending the code." };
  }
};

