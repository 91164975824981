import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { logoutUser, isAuthenticated } from "../Auth/AuthService";
import { getCredits } from "./NavbarService";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [credits, setCredits] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        const checkAuth = async () => {
            const response = await isAuthenticated();
            setLoggedIn(response.success);

            if (response.success) {
                const email = localStorage.getItem("email");
                if (email) {
                    const creditsResponse = await getCredits(email);
                    setCredits(creditsResponse || 0);
                }
            }
        };

        checkAuth();
    }, []);

    const handleSignOut = async () => {
        const response = await logoutUser();
        if (response.success) {
            alert(response.message);
            setLoggedIn(false);
            navigate("/login");
        } else {
            alert(response.message);
        }
    };

    return (
        <nav className="bg-black text-gray-400 p-4 pt-7">
            <div className="flex items-center justify-between">
                {/* Logo and Navigation Links */}
                <div className="flex items-center space-x-2 ml-[5%]">
                    <img src={`/logo.svg`} alt="Desync AI Logo" className="h-8 w-auto mr-2" />
                    <span className="text-[#A2A2A2] mr-2 md:inline hidden">/</span>
                    <div className="hidden md:flex space-x-4">
                        {loggedIn && (
                            <NavLink to="/home" className="text-[#A2A2A2] hover:text-white">
                                HOME
                            </NavLink>
                        )}
                        <a href="https://docs.desync.help/pages/overview/" target="_blank" rel="noreferrer" className="text-[#A2A2A2] hover:text-white">
                            DOCUMENTATION
                        </a>
                    </div>
                </div>

                {/* Right Section (Hidden on Small Screens) */}
                <div className="ml-auto hidden md:flex items-center space-x-4 mr-[5%]">
                    {loggedIn ? (
                        <>
                            <NavLink to="/add-credits" className="bg-[#007FFF] bg-opacity-20 text-[#0080FF] px-4 py-2 rounded-md hover:bg-opacity-30 transition">
                                {credits !== null ? `Add Credits: ${credits}` : "Add Credits"}
                            </NavLink>
                            <button
                                onClick={handleSignOut}
                                className="bg-gray-700 bg-opacity-20 text-gray-400 px-4 py-2 rounded-md hover:bg-opacity-30 transition"
                            >
                                Sign Out
                            </button>
                        </>
                    ) : (
                        <>
                            <NavLink to="/login" className="bg-[#007FFF] bg-opacity-20 text-[#0080FF] px-4 py-2 rounded-md hover:bg-opacity-30 transition">
                                Login
                            </NavLink>
                            <NavLink to="/register" className="bg-gray-700 bg-opacity-20 text-gray-400 px-4 py-2 rounded-md hover:bg-opacity-30 transition">
                                Register
                            </NavLink>
                        </>
                    )}
                </div>

                {/* Mobile Menu Button */}
                <button className="md:hidden text-white focus:outline-none mr-[5%]" onClick={() => setIsOpen(!isOpen)}>
                    ☰
                </button>
            </div>

            {/* Mobile Menu (Visible Only When Hamburger is Clicked) */}
            {isOpen && (
                <div className="md:hidden flex flex-col items-center space-y-4 mt-4">
                    {loggedIn && (
                        <NavLink to="/home" className="text-[#A2A2A2] hover:text-white" onClick={() => setIsOpen(false)}>
                            Home
                        </NavLink>
                    )}
                    <a href="https://docs.desync.help/pages/overview/" target="_blank" rel="noreferrer" className="text-[#A2A2A2] hover:text-white" onClick={() => setIsOpen(false)}>
                        DOCUMENTATION
                    </a>
                    {loggedIn ? (
                        <>
                            <NavLink to="/add-credits" className="text-[#A2A2A2] hover:text-white" onClick={() => setIsOpen(false)}>
                                Add Credits {credits !== null && `(${credits})`}
                            </NavLink>
                            <button
                                onClick={() => {
                                    handleSignOut();
                                    setIsOpen(false);
                                }}
                                className="bg-gray-700 bg-opacity-20 text-gray-400 px-4 py-2 rounded-md hover:bg-opacity-30 transition"
                            >
                                Sign Out
                            </button>
                        </>
                    ) : (
                        <>
                            <NavLink to="/login" className="text-[#A2A2A2] hover:text-white" onClick={() => setIsOpen(false)}>
                                Login
                            </NavLink>
                            <NavLink to="/register" className="text-[#A2A2A2] hover:text-white" onClick={() => setIsOpen(false)}>
                                Register
                            </NavLink>
                        </>
                    )}
                </div>
            )}
        </nav>
    );
};

export default Navbar;
