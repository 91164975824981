import React, { useState } from "react";
import { Link } from "react-router-dom";
import { registerUser } from "./AuthService";

const AuthRegister = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    const response = await registerUser({
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      password: formData.password,
    });

    if (response.success) {
      window.location.href = "/verify-email"; // Redirect to email verification
    } else {
      setError(response.message || "Registration failed. Please try again.");
    }
  };

  return (
    <div className="bg-black min-h-screen flex items-center justify-center px-6">
      <div className="w-full max-w-xl space-y-8">
        <h1 className="text-5xl font-extrabold text-white text-center">Create Account</h1>

        {/* Error Message */}
        {error && (
          <p className="text-red-400 bg-gray-800 p-4 rounded-lg text-center text-base">
            {error}
          </p>
        )}

        <form onSubmit={handleRegister} className="space-y-6">
          {/* First Name Field */}
          <div>
            <label htmlFor="firstName" className="block text-base font-medium text-gray-300 mb-2">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              className="w-full px-5 py-3 text-lg rounded-lg bg-gray-900 bg-opacity-20 text-white border border-gray-600 focus:ring-2 focus:ring-[#0080FF] outline-none"
              placeholder="Enter your first name"
              required
            />
          </div>

          {/* Last Name Field */}
          <div>
            <label htmlFor="lastName" className="block text-base font-medium text-gray-300 mb-2">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              className="w-full px-5 py-3 text-lg rounded-lg bg-gray-900 bg-opacity-20 text-white border border-gray-600 focus:ring-2 focus:ring-[#0080FF] outline-none"
              placeholder="Enter your last name"
              required
            />
          </div>

          {/* Email Field */}
          <div>
            <label htmlFor="email" className="block text-base font-medium text-gray-300 mb-2">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full px-5 py-3 text-lg rounded-lg bg-gray-900 bg-opacity-20 text-white border border-gray-600 focus:ring-2 focus:ring-[#0080FF] outline-none"
              placeholder="Enter your email"
              required
            />
          </div>

          {/* Password Field */}
          <div>
            <label htmlFor="password" className="block text-base font-medium text-gray-300 mb-2">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className="w-full px-5 py-3 text-lg rounded-lg bg-gray-900 bg-opacity-20 text-white border border-gray-600 focus:ring-2 focus:ring-[#0080FF] outline-none"
              placeholder="Enter your password"
              required
            />
          </div>

          {/* Confirm Password Field */}
          <div>
            <label htmlFor="confirmPassword" className="block text-base font-medium text-gray-300 mb-2">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              className="w-full px-5 py-3 text-lg rounded-lg bg-gray-900 bg-opacity-20 text-white border border-gray-600 focus:ring-2 focus:ring-[#0080FF] outline-none"
              placeholder="Confirm your password"
              required
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-[#007FFF] bg-opacity-20 text-[#0080FF] font-bold py-3 text-lg rounded-lg hover:bg-opacity-30 transition"
          >
            Register
          </button>
        </form>

        {/* Link to Login */}
        <p className="text-center text-lg text-gray-400">
          Already have an account?{" "}
          <Link to="/login" className="text-[#0080FF] font-bold hover:underline">
            Login here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default AuthRegister;
