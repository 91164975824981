import React, { useState, useEffect } from "react";
import { getAPIKeys, generateAPIKey, deactivateAPIKey } from "./HomeService.js";
import { Clipboard } from "lucide-react";

const Home = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState(null);
  const [copied, setCopied] = useState(null);
  const [quotas, setQuotas] = useState({});

  const handleQuotaChange = (key, newQuota) => {
    setQuotas((prevQuotas) => ({ ...prevQuotas, [key]: newQuota }));
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("ourUserId");
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      setError("User ID not found. Please log in again.");
    }

    const storedUserEmail = localStorage.getItem("email");
    if (storedUserEmail) {
      setEmail(storedUserEmail);
    } else {
      setError("User email not found. Please log in again.");
    }
  }, []);

  useEffect(() => {
    const fetchAPIKeys = async () => {
      if (!userId || !email) return;
      try {
        const result = await getAPIKeys(email);
        if (result.success) {
          setApiKeys(result.data || []);
        } else {
          setError(result.message);
        }
      } catch (error) {
        setError("An error occurred while fetching API keys.");
      }
    };
    fetchAPIKeys();
  }, [userId, email]);

  const handleGenerateAPIKey = async () => {
    if (!email) {
      setError("User ID not found. Please log in again.");
      return;
    }
    try {
      const result = await generateAPIKey(email);
      if (result.success) {
        setApiKeys((prevKeys) => [[result.data, true, result.data], ...prevKeys]);
      } else {
        setError(result.message);
      }
    } catch (error) {
      setError("An error occurred while generating an API key.");
    }
  };

  const deactivateKey = async (key, index, starredKey) => {
    try {
      const result = await deactivateAPIKey(key);
      if (result.success) {
        setApiKeys((prevKeys) => {
          const updatedKeys = [...prevKeys];
          updatedKeys[index] = [starredKey, false];
          return updatedKeys;
        });
      } else {
        setError(result.message);
      }
    } catch (error) {
      setError("An error occurred while deactivating the API key.");
    }
  };

  const handleCopy = (key) => {
    navigator.clipboard.writeText(key);
    setCopied(key);
    setTimeout(() => setCopied(null), 2000);
  };

  return (
    <section className="bg-black text-white min-h-screen flex flex-col items-center px-4 py-12">
      <div className="max-w-5xl w-full flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
        <h1 className="text-2xl md:text-3xl font-bold">API Keys</h1>
        <div className="flex flex-wrap justify-center md:justify-end space-x-2 md:space-x-4">
          <button
            onClick={handleGenerateAPIKey}
            className="bg-white text-black px-4 py-2 md:px-6 md:py-2 rounded-md text-sm md:text-lg font-semibold shadow-md transition"
            disabled={!userId}
          >
            + Create API Key
          </button>
          <a href="/add-credits">
            <button className="bg-gray-700 bg-opacity-20 text-gray-400 px-4 py-2 md:px-6 md:py-2 rounded-md text-sm md:text-lg font-semibold shadow-md hover:bg-opacity-30 transition">
              Add Credits
            </button>
          </a>
        </div>
      </div>

      {error && <p className="text-red-400 bg-gray-800 p-3 rounded-lg mb-6 text-lg">{error}</p>}

      {/* Responsive Table Wrapper */}
      <div className="w-full max-w-5xl overflow-x-auto">
        <table className="w-full text-left text-gray-300 text-sm md:text-base border-collapse hidden md:table">
          <thead>
            <tr className="bg-gray-800 text-gray-300">
              <th className="px-6 py-3 w-1/2">API Key</th>
              <th className="px-4 py-3">Status</th>
              {/* <th className="px-4 py-3">Quota</th> */}
              <th className="px-4 py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {apiKeys.length === 0 ? (
              <tr>
                <td className="px-4 py-3 text-center text-gray-400" colSpan="4">
                  No API keys found.
                </td>
              </tr>
            ) : (
              apiKeys.map(([key, status, fullKey], index) => (
                <tr key={index} className="border-b border-gray-700 hover:bg-gray-800 transition">
                  <td className="px-6 py-3 flex items-center w-1/3 whitespace-nowrap text-ellipsis">
                    {key}
                    {!key.includes("******") && (
                      <button onClick={() => handleCopy(key)} className="ml-2 text-gray-400 hover:text-white">
                        {copied === key ? "✅" : <Clipboard size={14} />}
                      </button>
                    )}
                  </td>
                  <td className="px-4 py-3 text-gray-400">{status ? "Active" : "Inactive"}</td>
                  {/* <td className="px-4 py-3 text-gray-400">
                    }
                    <input
                      type="text"
                      value={quotas[key] || "1000"}
                      onChange={(e) => handleQuotaChange(key, e.target.value)}
                      className="bg-transparent border border-gray-600 text-gray-300 px-2 py-1 rounded-md focus:outline-none focus:border-gray-400 w-24"
                    />
                   
                  </td> */}
                  <td className="px-4 py-3">
                    {status ? (
                      <button onClick={() => deactivateKey(fullKey, index, key)} className="bg-gray-700 bg-opacity-20 text-gray-400 px-4 py-2 rounded-md transition">
                        Deactivate
                      </button>
                    ) : (
                      <span className="text-gray-500 italic">Cannot Reactivate</span>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        {/* Mobile-friendly Card View */}
        <div className="md:hidden space-y-4 w-full">
          {apiKeys.length === 0 ? (
            <p className="text-center text-gray-400">No API keys found.</p>
          ) : (
            apiKeys.map(([key, status, fullKey], index) => (
              <div key={index} className="p-4 bg-gray-800 rounded-lg">
                <p className="text-white text-sm break-all">{key}</p>
                <div className="flex justify-between items-center mt-2 text-gray-400 text-sm">
                  <span>{status ? "Active" : "Inactive"}</span>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default Home;
